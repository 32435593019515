<template>
    <el-drawer v-model="isDrawerShow" :show-close="false" size="75%" @close="$emit('close')"
        :close-on-click-modal="true" ref="drawerRef" custom-class="adseries-chart-drawer">
        <template #title>
            <div class="top-container">
                <span>{{ type === 0 ? '广告系列' : type === 1 ? '广告组' : '广告' }}趋势折线图</span>
                <el-icon @click="$emit('close')">
                    <i class="iconfont icon-ylclose"></i>
                </el-icon>
            </div>
        </template>
        <template #default>
            <el-card v-loading="dailyLoading">
                <template #header>
                    <div class="card-header">
                        <span class="SubHead">{{ data.name }}</span>
                        <div>
                            <el-row :gutter="10">
                                <div class="type-button">
                                    <el-link :type="dailyForm.spent_type === 1 ? 'primary' : 'default'"
                                        :underline="false"
                                        @click="dailyForm.spent_type = 1; dailyForm.spent_time = base.getDefaultDuration(30); fetchDailySpent()">按天</el-link>
                                    <el-link :type="dailyForm.spent_type === 2 ? 'primary' : 'default'"
                                        :underline="false"
                                        @click="dailyForm.spent_type = 2; dailyForm.spent_time = base.getDefaultDuration(30); fetchDailySpent()">按周</el-link>
                                    <el-link :type="dailyForm.spent_type === 3 ? 'primary' : 'default'"
                                        :underline="false"
                                        @click="dailyForm.spent_type = 3; dailyForm.spent_time = base.getDefaultDuration(30); fetchDailySpent()">按月</el-link>
                                </div>
                                <el-date-picker v-model="dailyForm.spent_time" type="daterange" size="small"
                                    start-placeholder="开始日期" end-placeholder="结束日期" value-format="X" unlink-panels
                                    :shortcuts="shortcuts" :disabled-date="disabledDate" @focus="handleFocus"
                                    @calendar-change="handleChange" @blur="fetchDailySpent"></el-date-picker>
                            </el-row>
                        </div>
                    </div>
                </template>
                <!-- 趋势图表 -->
                <el-select v-model="dailyForm.type" @change="fetchDailySpent" style="width: 128px;">
                    <el-option v-for="(label, key) in calcType()" :key="key" :label="label" :value="key"></el-option>
                </el-select>
                <div id="cost" style="width: 100%;height:calc(100vh - 300px);"></div>
            </el-card>
        </template>
    </el-drawer>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import api from '@/request/api/ad'
import * as echarts from 'echarts';
import base from "@/base/filters/base";

const props = defineProps({
    data: { type: Object, default: {} },
    type: { type: Number, default: 0 }
})

const emit = defineEmits(['close', 'submit'])
const isDrawerShow = ref(true)
const dailyLoading = ref(false)
//账户趋势搜索表单
const dailyForm = ref({
    spent_time: base.getDefaultDuration(30),
    spent_type: 1,
    type: 'spend'
})

const pageCharts = ref({
    cpm: null,
    roas: null,
    cost: null,
    order: null,
    roas1: null,
    area: null,
})

let calcType = (key = '') => {
    let map = {
        spend: '账户消耗',
        clicks: '点击数',
        impressions: '展示数',
        reach: '转化数',
        cpc: 'CPC',
        cpm: 'CPM',
        cpa: 'CPA',
        ctr: 'CTR',
        roas: 'ROAS'
    }
    return key ? (map[key] ? map[key] : '') : map
}

const pageChartsFirstRendered = ref([])

/** 渲染图表通用方法 */
let renderChart = (elementId, data, type = 'line', xAxis = {}, yAxis = {}, colors = [], seriesStyle = {}, grid = {}, title = {}, legend = {}, tooltip = {}) => {
    if (pageChartsFirstRendered.value.indexOf(elementId.toLowerCase()) > -1) pageChartsFirstRendered.value.splice(pageChartsFirstRendered.value.indexOf(elementId.toLowerCase()), 1)

    if (!document.getElementById(elementId)) {
        if (pageChartsFirstRendered.value.indexOf(elementId.toLowerCase()) === -1) pageChartsFirstRendered.value.push(elementId.toLowerCase())
        return null
    }
    document.getElementById(elementId).removeAttribute("_echarts_instance_")
    let chart = echarts.init(document.getElementById(elementId))

    let series = []
    if (data && data.series && data.series.length > 0) {
        data.series.forEach(item => {
            series.push(Object.assign({
                name: item.name ? item.name : '',
                data: item.data ? item.data : [],
                type: type,
            }, seriesStyle))
        })
    }

    chart.setOption({
        title: title,
        color: colors.length ? colors : ['#2b82ff'],
        tooltip: Object.assign({
            trigger: 'axis'
        }, tooltip),
        legend: Object.assign(data && data.legend ? { data: data.legend } : {}, legend),
        xAxis: Object.assign({
            type: 'category',
            boundaryGap: false,
            data: [],
            show: false
        }, data && data.xAxis ? data.xAxis : {}, xAxis),
        yAxis: Object.assign({
            type: 'value',
        }, yAxis),
        series: series,
        grid: Object.assign({
            top: '5px',
            bottom: '4px'
        }, grid)
    })

    chart.on('finished', () => {
        if (pageChartsFirstRendered.value.indexOf(elementId.toLowerCase()) === -1) pageChartsFirstRendered.value.push(elementId.toLowerCase())
    })

    return chart
}

const fetchDailySpent = async () => {
    dailyLoading.value = true
    const user = JSON.parse(localStorage.getItem('CurrentFBAuthUser'))
    let res = await api.series.getInsights({
        spent_time: dailyForm.value.spent_time,
        spent_type: dailyForm.value.spent_type,
        type: dailyForm.value.type,
        id: props.data.id,
        user_id: user.fb_user_id
    }).finally(() => dailyLoading.value = false)

    pageCharts.value.cost = renderChart(
        'cost',
        res.data,
        'line',
        { show: true }, {},
        ['#2b82ff', '#00f1a2', '#ffb900', '#F56C6C', '#7749fc', '#73C0DE'],
        {}, {
        top: '50px',
        left: '70px',
        right: '40px',
        bottom: '40px'
    }, {}, { type: 'scroll', top: 'top', right: '40px' })
}

const shortcuts = ref([
    {
        text: '昨天',
        value: () => {
            let start = new Date()
            let end = new Date()
            start.setTime(start.getTime() - 8.64e7)
            end.setTime(end.getTime() - 8.64e7)
            return [start, end]
        }
    },
    {
        text: '近7天',
        value: () => {
            let start = new Date()
            let end = new Date()
            start.setTime(start.getTime() - 8.64e7 * 7)
            end.setTime(end.getTime() - 8.64e7)
            return [start, end]
        }
    },
    {
        text: '近30天',
        value: () => {
            let start = new Date()
            let end = new Date()
            start.setTime(start.getTime() - 8.64e7 * 30)
            end.setTime(end.getTime() - 8.64e7)
            return [start, end]
        }
    }
])

const chooseDay = ref(null)
const handleChange = (val) => {
    const [pointDay] = val
    chooseDay.value = pointDay
}
const handleFocus = () => {
    chooseDay.value = null
}
const disabledDate = (time) => {
    if (!chooseDay.value) {
        return false
    }
    let timeRange = dailyForm.value.spent_type === 1 ? 30 : dailyForm.value.spent_type === 2 ? 180 : 365
    const con1 = new Date(chooseDay.value).getTime() - timeRange * 24 * 60 * 60 * 1000
    const con2 = new Date(chooseDay.value).getTime() + timeRange * 24 * 60 * 60 * 1000
    return time < con1 || time > con2
}

onMounted(() => {
    fetchDailySpent()
})
</script>


<style>
.adseries-chart-drawer {
    .el-drawer__header {
        height: 64px;
        padding: 0;
        margin: 0;
        display: block;
    }

    .el-drawer__body {
        padding: 24px;
        background: #F5F6FA;
        margin: 0;
    }

    .el-card {
        border-radius: 8px;
    }
}
</style>
<style lang="less" scoped>
.top-container {
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 24px;
    border-bottom: 1px solid #F0F0F0;

    span {
        font-size: 16px;
        font-family: PingFang SC-Medium;
        font-weight: 600;
        color: #262626;
        line-height: 24px;
    }

    img {
        width: 20px;
        height: 20px;
        cursor: pointer;
    }
}

.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .SubHead {
        font-size: 16px;
        font-family: PingFang SC-Medium;
        font-weight: 600;
        color: #262626;
        line-height: 24px;
    }

    .type-button {
        margin-right: 32px;

        :deep(.el-link) {
            font-size: 14px;
            font-family: PingFang SC-Regular;
            font-weight: 400;
            margin-left: 24px;
            line-height: 32px;
        }

        :deep(.el-link--default) {
            color: #595959;
        }

        :deep(.el-link--primary) {
            color: #2b82ff;
        }
    }
}
</style>